import React from 'react'
import { Seo, PageHeader } from '../components'
import { Image } from 'semantic-ui-react'
// Styles
import {
  Wrapper,
  Container,
  Title,
  StyledSpan,
  StyledImage,
  StyledList,
  StyledOlList,
  ListItem,
} from '../styles/CorporatePage.style'

// Utils
import { formatParagraph } from '../utils/prismicUtils'

// Data
import { usePrismic } from '../context/PrismicContext'

const QProgram = () => {
  const {
    prismicData: { prismicProgramPage },
  } = usePrismic()

  const {
    living_quintessentially_title,
    living_quintessentially_content,
    q_science_title,
    q_science_image,
    q_science_content,
    q_kids_club_title,
    q_kids_club_image,
    q_kids_club_content,
    my_qfit_image,
    my_qfit_content,
    q_care_image,
    q_care_content,
    q_reward_title,
    q_reward_image,
    q_reward_content,
    q_reward_point_system_title,
    q_reward_point_system_content,
  } = prismicProgramPage

  const formatContent = content => {
    let isListOpened = false
    const olListItems = []
    const ulListItems = []

    return content.map((item, index) => {
      if (item.type === 'o-list-item' || item.type === 'list-item') {
        isListOpened = true

        if (item.type === 'o-list-item') {
          olListItems.push(item)
        } else {
          ulListItems.push(item)
        }

        return <></>
      } else {
        if (isListOpened) {
          isListOpened = false

          if (olListItems.length > 0) {
            return (
              <>
                <StyledOlList>
                  {olListItems.map(listItem => (
                    <ListItem>
                      <StyledSpan
                        dangerouslySetInnerHTML={{
                          __html: formatParagraph(listItem),
                        }}
                      />
                    </ListItem>
                  ))}
                </StyledOlList>
                <StyledSpan
                  key={`sciences-${index}`}
                  dangerouslySetInnerHTML={{ __html: formatParagraph(item) }}
                />
              </>
            )
          } else if (ulListItems.length > 0) {
            return (
              <>
                <StyledList>
                  {ulListItems.map(listItem => (
                    <ListItem>
                      <StyledSpan
                        dangerouslySetInnerHTML={{
                          __html: formatParagraph(listItem),
                        }}
                      />
                    </ListItem>
                  ))}
                </StyledList>
                <StyledSpan
                  key={`sciences-${index}`}
                  dangerouslySetInnerHTML={{ __html: formatParagraph(item) }}
                />
              </>
            )
          }
        }

        olListItems.splice(0, olListItems.length)
        ulListItems.splice(0, ulListItems.length)

        return (
          <StyledSpan
            key={`sciences-${index}`}
            dangerouslySetInnerHTML={{ __html: formatParagraph(item) }}
          />
        )
      }
    })
  }

  return (
    <>
      <Seo title="Q Program" />
      <Wrapper>
        <PageHeader exitRoute="/">
          {living_quintessentially_title[0].text}
        </PageHeader>
        <Container padded>
          {living_quintessentially_content.map((item, index) => (
            <StyledSpan
              key={`culture-${index}`}
              dangerouslySetInnerHTML={{ __html: formatParagraph(item) }}
            />
          ))}
        </Container>

        {/* <QProgramPeopleContainer backgroundImg={q_science_image.url} /> */}
        <Image src={q_science_image.url} fluid />
        <Container padded black noPaddingBottom>
          <Title>{q_science_title[0].text}</Title>
        </Container>
        <Container black padded>
          {formatContent(q_science_content)}
        </Container>

        <Container>
          <StyledImage src={q_kids_club_image.url} />
        </Container>
        <Container padded noPaddingBottom>
          <Title>{q_kids_club_title[0].text}</Title>
        </Container>
        <Container padded>{formatContent(q_kids_club_content)}</Container>

        <Container>
          <StyledImage src={my_qfit_image.url} />
        </Container>
        <Container black padded>
          {formatContent(my_qfit_content)}
        </Container>

        <Container gray padded>
          <StyledImage src={q_care_image.url} />
        </Container>
        <Container padded noPaddingTop gray>
          {formatContent(q_care_content)}
        </Container>

        <Container>
          <StyledImage src={q_reward_image.url} />
        </Container>

        <Container padded noPaddingBottom>
          <Title bigger>{q_reward_title[0].text}</Title>
        </Container>
        <Container padded noPaddingBottom>
          {formatContent(q_reward_content)}
        </Container>

        <Container padded noPaddingBottom>
          <Title bigger>{q_reward_point_system_title[0].text}</Title>
        </Container>
        <Container padded noPaddingBottom noPaddingTop>
          {formatContent(q_reward_point_system_content)}
        </Container>
      </Wrapper>
    </>
  )
}

export default QProgram
